import { PureComponent } from "react";

import SignupFormComponent from "components/core/signups/base-signup-form/signup-form-component";
import { NUMBER_OF_PAYING_TEAMS } from "/constants/globals";

import { componentStyle, signupBoxStyle } from "./exit-popup-signup-component.scss";

export default class ExitPopupSignup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: ""
    };

    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(e) {
    const { value } = e.target.value;
    this.setState({
      value
    });
  }

  render() {
    const { headerText, inputPlaceholder, buttonText, footerText, origin, onClickFooterText } = this.props;
    const { value } = this.state;

    return (
      <div className="exit-popup-signup">
        {headerText && <div className="header-text">{headerText}</div>}

        <div className="exit-popup-signup-box">
          <SignupFormComponent
            placeholder={inputPlaceholder}
            ctaText={buttonText}
            useCtaText={!!buttonText}
            origin={origin}
            forceDefaultForm={true}
          />
        </div>

        {footerText && (
          <div className="footer-text" onClick={onClickFooterText}>
            {footerText}
          </div>
        )}

        <style jsx>{componentStyle}</style>

        <style jsx>{signupBoxStyle}</style>
      </div>
    );
  }
}

ExitPopupSignup.defaultProps = {
  headerText: `Join ${NUMBER_OF_PAYING_TEAMS}+ teams who are passionate about monday.com`,
  buttonText: "Get free access now",
  inputPlaceholder: "Enter your work email",
  footerText: "No thanks"
};
