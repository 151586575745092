import { PureComponent } from "react";
import ExitPopupSignup from "../exit-popup-signup/exit-popup-signup-component";
import { componentStyle, closeIconStyle } from "./exit-popup-content-component.scss";

export default class ExitPopupContent extends PureComponent {
  render() {
    const {
      title,
      subtitle,
      signupHeaderText,
      singupInputPlaceholder,
      signupButtonText,
      signupFooterText,
      onClose,
      origin
    } = this.props;

    return (
      <div className="exit-popup-content-component">
        <div className="exit-popup-close">
          <a className="x-button pointer tc" rel="nofollow" onClick={onClose}>
            +
          </a>
        </div>

        <h3 className="content-title">{title}</h3>
        <p className="content-subtitle">{subtitle}</p>

        <ExitPopupSignup
          headerText={signupHeaderText}
          inputPlaceholder={singupInputPlaceholder}
          buttonText={signupButtonText}
          footerText={signupFooterText}
          onClickFooterText={onClose}
          origin={origin}
        />

        <style jsx>{componentStyle}</style>
        <style jsx>{closeIconStyle}</style>
      </div>
    );
  }
}
