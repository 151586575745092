import { PureComponent } from "react";
import ouibounce from "ouibounce";
import { trackEvent } from "/services/bigbrain-service";
import { EXIT_POPUP_OPEN, EXIT_POPUP_CLOSE } from "/constants/bigbrain-event-types";
import { NUMBER_OF_PAYING_TEAMS } from "/constants/globals";
import { withGenericConfig } from "contexts/generic-config-context";
import { withAbTests } from "contexts/ab-tests-context";
import { EXIT_POPUP_ORIGIN } from "./exit-popup-constants";
import { VIEWED_OUI_BOUNCE_MODAL_COOKIE } from "constants/cookies";
import { shouldSeeReviveExitPopupAbTest } from "services/ab-tests/acquisition-ab-test-service";

import ModalBackground from "/components/enhancements/modal-background/modal-background-component";
import ExitPopupContent from "./exit-popup-content/exit-popup-content-component";

class ExitPopup extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this._ouibounce = null;
    this.onExitIntent = this.onExitIntent.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.initOuibounce();
  }

  initOuibounce() {
    const { timer, aggressive, sensitivity, delay, cookieExpire, cookieDomain, cookieName } = this.props;
    this._ouibounce = ouibounce(false, {
      timer,
      aggressive,
      sensitivity,
      delay,
      cookieExpire,
      cookieDomain,
      cookieName,
      callback: this.onExitIntent
    });
  }

  onExitIntent() {
    const { abTests } = this.props;

    if (!shouldSeeReviveExitPopupAbTest(abTests, true)) return;

    this.props.onExitIntentCallback && this.props.onExitIntentCallback();
    this.setState({ isOpen: true });
    trackEvent(EXIT_POPUP_OPEN);
  }

  onClose() {
    this.props.onCloseExitPopupCallback && this.props.onCloseExitPopupCallback();
    this.setState({ isOpen: false });
    trackEvent(EXIT_POPUP_CLOSE);
  }

  render() {
    const { isOpen } = this.state;
    const { title, subtitle, signupConfig, translate, translateWithParam } = this.props;

    const titleToUse = title || translate("exitPopup.title");
    const subtitleToUse = subtitle || translate("exitPopup.subtitle");

    const signupHeaderTextToUse =
      (signupConfig && signupConfig.headerText) || translateWithParam("exitPopup.signupTitle", NUMBER_OF_PAYING_TEAMS);
    const inputPlaceholderToUse =
      (signupConfig && signupConfig.inputPlaceholder) || translate("exitPopup.signupInputPlaceholder");
    const buttonTextToUse = (signupConfig && signupConfig.buttonText) || translate("exitPopup.signupButtonText");
    const footerTextToUse = (signupConfig && signupConfig.footerText) || translate("exitPopup.footerText");

    return isOpen ? (
      <div className="exit-popup-overlay">
        <ModalBackground />
        <ExitPopupContent
          title={titleToUse}
          subtitle={subtitleToUse}
          signupHeaderText={signupHeaderTextToUse}
          singupInputPlaceholder={inputPlaceholderToUse}
          signupButtonText={buttonTextToUse}
          signupFooterText={footerTextToUse}
          onClose={this.onClose}
          origin={EXIT_POPUP_ORIGIN}
        />
      </div>
    ) : (
      <div />
    );
  }
}

ExitPopup.defaultProps = {
  timer: 100,
  aggressive: false,
  sensitivity: 5,
  delay: 200,
  cookieExpire: 30,
  cookieDomain: process.env.COOKIE_DOMAIN,
  cookieName: VIEWED_OUI_BOUNCE_MODAL_COOKIE
};

export default withAbTests(withGenericConfig(ExitPopup));
