import componentStyles from "./modal-background-component.scss";
const ModalBackgroundComponent = (props) => {
  const { onClick } = props;
  return (
    <div className="modal-background" onClick={onClick}>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default ModalBackgroundComponent;
