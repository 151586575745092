import css from "styled-jsx/css";
import colors from "/styles/colors";
import { TABLET_QUERY_MAX_WIDTH } from "/constants/vp-sizes";

export const componentStyle = css`
  .header-text {
    margin-bottom: 16px;
  }
`;

export const signupBoxStyle = css.global`
  .exit-popup-signup {
    .footer-text {
      cursor: pointer;
      color: darken(#fff, 10%);
    }

    .exit-popup-signup-box {
      display: flex;
      justify-content: center;

      .signup-form {
        margin-bottom: 16px;
        margin-top: 0;
      }

      .signup-input {
        height: 56px;
        width: 224px;

        padding: 0 24px;
        border-radius: 50px 0 0 50px;
        border: 1px solid transparent;
        margin-right: 0;

        color: ${colors["black"]};

        vertical-align: bottom;

        font-size: 0.875rem;

        &:focus {
          outline: 0;
        }
      }

      .signup-button {
        height: 56px;
        min-width: 200px;

        background: #f4ce2f;
        border-radius: 0 50px 50px 0;
        border: 1px solid transparent;
        padding: 0 10px;

        color: #333;
        font-size: 0.875rem;
        vertical-align: bottom;

        &:hover {
          background: darken(#f4ce2f, 5%);
          border: #f4ce2f;
        }
      }
    }
  }

  @media (max-width: ${TABLET_QUERY_MAX_WIDTH}) {
    .exit-popup-signup {
      .exit-popup-signup-box .signup-form-core-component-wrapper form {
        flex-direction: row;

        .signup-button-wrapper {
          width: auto;
          padding: 8px 0 16px 0;
        }
      }
    }
  }
`;
