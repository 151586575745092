import css from "styled-jsx/css";
import colors from "/styles/colors";
import { MODAL_BACKGROUND } from "/constants/z-index";

export const componentStyle = css`
  .exit-popup-content-component {
    position: fixed;
    z-index: ${MODAL_BACKGROUND + 1};

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 720px;
    padding: 56px 40px 40px 40px;
    border-radius: 4px;

    background: #333;
    color: #fff;

    text-align: center;

    .content-title {
      font-size: 3vw;
      font-weight: 500;
      margin: 0;
    }

    .content-subtitle {
      font-size: 1.2vw;
      margin-bottom: 56px;
    }
  }
`;

export const closeIconStyle = css`
  .exit-popup-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    background: linear-gradient(45deg, #787878 0%, #787878 50%, #343434 50%, #222 61%);
    border-radius: 0px 0px 0px 5px;

    .x-button {
      position: absolute;
      transform: rotate(45deg);
      top: -4px;
      right: 4px;
      line-height: 1;
      font-size: 2.25rem;
      color: #787878;
      transition: color 0.1s ease-in-out;
      text-decoration: none;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        color: #c4c4c4;
        text-decoration: none;
      }
    }
  }
`;
