import { css } from "styled-jsx/css";
import colors from "/styles/colors";
import { MODAL_BACKGROUND } from "/constants/z-index";

export default css`
  .modal-background {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: ${colors["black"]};
    opacity: 0.9;
    top: 0;
    left: 0;
    z-index: ${MODAL_BACKGROUND};
  }
`;
