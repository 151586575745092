import { PureComponent, Fragment } from "react";
import { withGenericConfig } from "contexts/generic-config-context";
import ExitPopupComponent from "/components/addons/exit-popup/exit-popup-component";
import { fireGtmEvent } from "client/services/gtm-service/gtm-service";
import DataLayerEventTypes from "constants/data-layer-event-types";
import { withAbTests } from "contexts/ab-tests-context";

class CoreAddonsComponent extends PureComponent {
  componentDidMount() {
    //TODO: Remove and change GTM trigger to page load
    fireGtmEvent(DataLayerEventTypes.SHOW_COOKIEHUB_DIALOG_EVENT_NAME);
    //If we see that CookieHub Geolocation isn't working well we can fire a GTM event only for relevant countries + another for the rest to launch all cookies
  }

  render() {
    const { exitPopup, exitPopupConfig } = this.props;

    return <Fragment>{exitPopup && <ExitPopupComponent {...exitPopupConfig} />}</Fragment>;
  }
}

export default withAbTests(withGenericConfig(CoreAddonsComponent));
